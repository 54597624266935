import React from "react";
import theme from "theme";
import { Theme, Strong, Text, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"rooms"} />
		<Helmet>
			<title>
				Nos services | Serenity Escape Resort Services
			</title>
			<meta name={"description"} content={"Laissez-vous séduire par l'élégance - Découvrez l'éventail de luxes de Serenity Escape\n"} />
			<meta property={"og:title"} content={"Nos services | Serenity Escape Resort Services"} />
			<meta property={"og:description"} content={"Laissez-vous séduire par l'élégance - Découvrez l'éventail de luxes de Serenity Escape\n"} />
			<meta property={"og:image"} content={"https://brittanybeaches.live/images/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://brittanybeaches.live/images/images.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://brittanybeaches.live/images/images.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://brittanybeaches.live/images/images.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://brittanybeaches.live/images/images.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://brittanybeaches.live/images/images.png"} />
			<meta name={"msapplication-TileImage"} content={"https://brittanybeaches.live/images/images.png"} />
		</Helmet>
		<Components.Header />
		<Section
			padding="90px 0 90px 0"
			sm-padding="40px 0"
			sm-min-height="auto"
			display="flex"
			quarkly-title="Advantages/Features-8"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				grid-gap="5%"
				sm-min-width="280px"
				lg-flex-wrap="wrap"
				display="flex"
			/>
			<Box
				display="flex"
				width="35%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 30px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
			>
				<Text
					as="h1"
					margin="0px 0px 8px 0px"
					font="--lead"
					color="--primary"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 16px 0px"
					md-padding="0px 0px 0px 0px"
					text-align="left"
					md-text-align="center"
					sm-text-align="left"
				>
					<Strong sm-text-align="left">
						Nos services
					</Strong>
				</Text>
				<Text
					as="h1"
					margin="0px 0px 24px 0px"
					font="--headline2"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
					text-align="left"
					md-text-align="center"
					sm-text-align="left"
				>
					Points forts des services
				</Text>
				<Text
					as="h1"
					margin="0px 0px 0px 0px"
					font="--base"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					text-align="left"
					md-text-align="center"
					sm-text-align="left"
				>
					Le Serenity Escape Resort est une symphonie de services qui répondent à votre désir de détente et d'élégance. De notre spa de classe mondiale à l'art culinaire de notre restaurant, chaque aspect est conçu pour offrir une expérience de sérénité.
				</Text>
			</Box>
		</Section>
		<Section padding="90px 0 100px 0" quarkly-title="List-2">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="0 34px"
				md-grid-template-columns="1fr"
				md-grid-gap="36px 0"
			>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 20px 0px" font="normal 500 28px/1.2 --fontFamily-sans">
						Hébergement et confort
					</Text>
					<Image src="https://brittanybeaches.live/images/s1.jpg" display="block" margin="0px 0px 20px 0px" max-width="100%" />
					<Text margin="0px 0px 0px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#6a7179">
						- Suites de luxe : Détendez-vous dans nos suites spacieuses au décor élégant, à la literie moelleuse et à la vue sereine, pour une retraite reposante.
						<br />
						<br />
						- Service en chambre : Découvrez notre service d'étage 24 heures sur 24, 7 jours sur 7, avec un menu qui vous apporte nos délices culinaires directement à votre porte.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 20px 0px" font="normal 500 28px/1.2 --fontFamily-sans">
						Spa et bien-être
					</Text>
					<Image src="https://brittanybeaches.live/images/s2.jpg" display="block" margin="0px 0px 20px 0px" max-width="100%" />
					<Text margin="0px 0px 0px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#6a7179">
						- Spa à service complet : Choisissez parmi un menu de soins de spa comprenant des massages, des soins du visage et des enveloppements corporels conçus pour rajeunir.
						<br />
						<br />
						- Centre de remise en forme : Maintenez votre routine de bien-être avec des équipements de pointe et des séances d'entraînement personnel dans notre salle de sport entièrement équipée.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 20px 0px" font="normal 500 28px/1.2 --fontFamily-sans">
						Gastronomie
					</Text>
					<Image src="https://brittanybeaches.live/images/s3.jpg" display="block" margin="0px 0px 20px 0px" max-width="100%" />
					<Text margin="0px 0px 0px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#6a7179">
						- Cuisine gastronomique : Notre restaurant sur place propose une expérience gastronomique avec des plats élaborés à partir des produits locaux les plus frais.
						<br />
						<br />
						- Bar au bord de la piscine : Sirotez des cocktails de marque ou dégustez un repas léger au bord de la piscine, entouré par les sons paisibles de la nature.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="20px 0 100px 0" quarkly-title="List-2">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="0 34px"
				md-grid-template-columns="1fr"
				md-grid-gap="36px 0"
			>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 20px 0px" font="normal 500 28px/1.2 --fontFamily-sans">
						Loisirs et activités
					</Text>
					<Image src="https://brittanybeaches.live/images/s4.jpg" display="block" margin="0px 0px 20px 0px" max-width="100%" />
					<Text margin="0px 0px 0px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#6a7179">
						- Piscine : Nagez tranquillement ou détendez-vous au bord de notre piscine, un endroit serein pour profiter du soleil et d'une ambiance paisible.
						<br />
						<br />
						- Promenades dans les jardins : Promenade dans nos jardins magnifiquement aménagés, un moyen idéal de se reconnecter avec la nature.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 20px 0px" font="normal 500 28px/1.2 --fontFamily-sans">
						Accueil d'événements
					</Text>
					<Image src="https://brittanybeaches.live/images/s5.jpg" display="block" margin="0px 0px 20px 0px" max-width="100%" />
					<Text margin="0px 0px 0px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#6a7179">
						- Des lieux élégants : Notre établissement est équipé pour accueillir vos événements, qu'il s'agisse de réunions intimes ou de célébrations plus importantes, le tout dans un cadre serein.
						<br />
						<br />
						- Planification professionnelle : Laissez nos spécialistes vous aider à créer un événement parfait qui reflète votre vision.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 20px 0px" font="normal 500 28px/1.2 --fontFamily-sans">
						Exploration et détente
					</Text>
					<Image src="https://brittanybeaches.live/images/s6.jpg" display="block" margin="0px 0px 20px 0px" max-width="100%" />
					<Text margin="0px 0px 0px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#6a7179">
						- Bibliothèque et salon : Évadez-vous dans les pages d'un livre ou profitez d'un moment de tranquillité dans notre bibliothèque et notre salon bien aménagés.
						<br />
						<br />
						- Concierge d'activités : Notre service de conciergerie peut organiser des excursions locales ou suggérer des activités pour enrichir votre séjour.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
					width="100%"
					height="auto"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						src="https://brittanybeaches.live/images/s7.jpg"
						object-fit="cover"
						width="100%"
						top="auto"
						right={0}
						bottom="0px"
						min-height="100%"
						position="absolute"
						display="block"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				lg-order="1"
				width="50%"
				align-items="flex-start"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
			>
				<Text
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					md-text-align="left"
				>
					Contacter Serenity Escape
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Pour découvrir la gamme complète de nos services ou pour réserver votre escapade sereine, veuillez contacter notre personnel accueillant. Nous nous engageons à faire en sorte que votre séjour soit l'incarnation de la détente et du luxe sur mesure.
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});